import { Icon } from "@iconify/react";
import { Card, Divider } from "antd";
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from "chart.js";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { useQuery } from "react-query";
import { useRecoilValue, useSetRecoilState } from "recoil";
import BellCardIcon from "../../components/UI/Dashboard/Icons/BellCardIcon";
import BookCardIcon from "../../components/UI/Dashboard/Icons/BookCardIcon";
import BubbleCardIcon from "../../components/UI/Dashboard/Icons/BubbleCardIcon";
import UserCardIcon from "../../components/UI/Dashboard/Icons/UserCardIcon";
import Table from "../../components/UI/Dashboard/Table/Table";
import api from "../../config/api";
import { mobileNavState, organizationState, titleState } from "../../store";
import { numberFormatter } from "../../utils/formatter";

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title
);

function Dashboard() {
    const setTitle = useSetRecoilState(titleState);
    const setMobileNav = useSetRecoilState(mobileNavState);
    const organization = useRecoilValue(organizationState);
    const [totalUsers, setTotalUsers] = useState(0);
    const [totalCourses, setTotalCourses] = useState(0);
    const [totalDiscussions, setTotalDiscussions] = useState(0);
    const [totalActiveUsers, setTotalActiveUsers] = useState(0);
    const [totalMales, setTotalMales] = useState(0);
    const [totalFemales, setTotalFemales] = useState(0);
    const [totalPublishedCourses, setTotalPublishedCourses] = useState(0);
    const [totalUnpublishedCourses, setTotalUnpublishedCourses] = useState(0);
    const [userCreatedDiscussions, setUserCreatedDiscussions] = useState(0);
    const [adminCreatedDiscussions, setAdminCreatedDiscussions] = useState(0);
    const [activeDiscussions, setActiveDiscussions] = useState(0);
    const [inactiveDiscussions, setInactiveDiscussions] = useState(0);
    const [appUsageDates, setAppUsageDates] = useState([]);
    const [appUsageRecords, setAppUsageRecords] = useState([]);
    const [ageCategoryCounts, setAgeCategoryCounts] = useState([]);
    const [visitedCoursesData, setVisitedCoursesData] = useState([]);
    const [completedCoursesData, setCompletedCoursesData] = useState([]);
    const [ratedCoursesData, setRatedCoursesData] = useState([]);
    const [commentedCoursesData, setCommentedCoursesData] = useState([]);
    const [engagedDiscussionsData, setEngagedDiscussionsData] = useState([]);

    useEffect(() => {
        setTitle("Dashboard");
    }, [setTitle]);

    useEffect(() => {
        setMobileNav(false);
    }, [setMobileNav]);

    const fetchHeaderStats = async () => {
        const { data } = await api.get(
            `/admin/dashboard_count_summary?organization_id=${organization}`
        );
        return data;
    };

    const fetchGenderStats = async () => {
        const { data } = await api.get(
            `/admin/dashboard_users_demography?organization_id=${organization}`
        );
        return data;
    };

    const fetchCourseStats = async () => {
        const { data } = await api.get(
            `/admin/dashboard_courses_statistics?organization_id=${organization}`
        );
        return data;
    };

    const fetchAppUsage = async () => {
        const { data } = await api.get(
            `/admin/dashboard_active_users?organization_id=${organization}&reference_day=${0}`
        );
        return data;
    };

    const fetchAgeCategory = async () => {
        const ageGroups = {
            organization_id: organization,
            categories: [
                {
                    min_age: 12,
                    max_age: 14,
                },
                {
                    min_age: 15,
                    max_age: 17,
                },
                {
                    min_age: 18,
                    max_age: 21,
                },
                {
                    min_age: 21,
                    max_age: 25,
                },
                {
                    min_age: 25,
                    max_age: 100,
                },
            ],
        };

        const { data } = await api.post(
            `/admin/dashboard_users_age_category`,
            ageGroups
        );

        return data;
    };

    const fetchDiscussionStats = async () => {
        const { data } = await api.get(
            `/admin/dashboard_discussions_statistics?organization_id=${organization}`
        );
        return data;
    };

    useQuery(["header-stats", organization], fetchHeaderStats, {
        onSuccess: (response) => {
            const { result } = response;
            setTotalUsers(result.users_count);
            setTotalCourses(result.courses_count);
            setTotalDiscussions(result.discussions_count);
            setTotalActiveUsers(result.active_users_count);
        },
    });

    useQuery(["gender-stats", organization], fetchGenderStats, {
        onSuccess: (response) => {
            const { result } = response;
            setTotalMales(result.male_users_count);
            setTotalFemales(result.female_users_count);
        },
    });

    useQuery(["course-stats", organization], fetchCourseStats, {
        onSuccess: (response) => {
            const { result } = response;
            setTotalPublishedCourses(result.published_courses_count);
            setTotalUnpublishedCourses(result.not_published_courses_count);

            setVisitedCoursesData(
                _.slice(
                    _.orderBy(result.visited_courses, ["VisitCount"], ["desc"]),
                    0,
                    10
                )
            );
            setCompletedCoursesData(
                _.slice(
                    _.orderBy(
                        result.completed_courses,
                        ["CompletedCount"],
                        ["desc"]
                    ),
                    0,
                    5
                )
            );
            setRatedCoursesData(
                _.slice(
                    _.orderBy(result.rated_courses, ["RateCount"], ["desc"]),
                    0,
                    5
                )
            );
            setCommentedCoursesData(
                _.slice(
                    _.orderBy(
                        result.comment_courses,
                        ["CommentCount"],
                        ["desc"]
                    ),
                    0,
                    5
                )
            );
        },
    });

    useQuery(["app-usage", organization], fetchAppUsage, {
        onSuccess: (response) => {
            const { result } = response;
            setAppUsageDates(result.record_date);
            setAppUsageRecords(result.active_users);
        },
    });

    useQuery(["age-category", organization], fetchAgeCategory, {
        onSuccess: (response) => {
            const { result } = response;
            const userCounts = [];

            for (let ageCategory of result) {
                userCounts.push(ageCategory.count);
            }

            setAgeCategoryCounts(userCounts);
        },
    });

    useQuery(["discussion-stats", organization], fetchDiscussionStats, {
        onSuccess: (response) => {
            const { result } = response;

            setActiveDiscussions(result.active_discussions_count);
            setInactiveDiscussions(result.in_active_discussions_count);
            setEngagedDiscussionsData(
                _.slice(
                    _.orderBy(
                        result.engaging_discussions,
                        ["EngagedUsers"],
                        ["desc"]
                    ),
                    0,
                    5
                )
            );
            setAdminCreatedDiscussions(result.admin_discussions_count);
            setUserCreatedDiscussions(result.users_discussions_count);
        },
    });

    const usersData = {
        labels: null,
        datasets: [
            {
                data: [totalFemales, totalMales],
                backgroundColor: [
                    "rgba(229, 109, 83, 1)",
                    "rgba(123, 198, 176, 1)",
                ],
                borderWidth: 0,
            },
        ],
    };

    const coursesData = {
        labels: null,
        datasets: [
            {
                data: [totalUnpublishedCourses, totalPublishedCourses],
                backgroundColor: [
                    "rgba(237, 148, 43, 1)",
                    "rgba(4, 93, 184, 1)",
                ],
                borderWidth: 0,
            },
        ],
    };

    const discussionsCreationData = {
        labels: null,
        datasets: [
            {
                data: [userCreatedDiscussions, adminCreatedDiscussions],
                backgroundColor: [
                    "rgba(4, 93, 184, 1)",
                    "rgba(237, 148, 43, 1)",
                ],
                borderWidth: 0,
            },
        ],
    };

    const discussionsStatusData = {
        labels: null,
        datasets: [
            {
                data: [activeDiscussions, inactiveDiscussions],
                backgroundColor: [
                    "rgba(235, 175, 166, 1)",
                    "rgba(59, 196, 156, 1)",
                ],
                borderWidth: 0,
            },
        ],
    };

    const lineGraphData = {
        labels: appUsageDates,
        datasets: [
            {
                label: "Users",
                data: appUsageRecords,
                borderColor: "rgba(29, 199, 234, 1)",
                backgroundColor: "rgba(29, 199, 234, 1)",
            },
        ],
    };

    const lineGraphOptions = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                grid: {
                    color: "#ffffff",
                    borderDash: [1.5, 1.5],
                },
                ticks: {
                    color: "#ffffff",
                    precision: 0,
                },
                beginAtZero: true,
            },
            x: {
                grid: {
                    color: "#ffffff",
                    borderDash: [1.5, 1.5],
                },
                ticks: {
                    color: "#ffffff",
                },
            },
        },
    };

    const barGraphData = {
        labels: ["12-14", "15-17", "18-21", "21-25", "25+"],
        datasets: [
            {
                label: "Count",
                data: ageCategoryCounts,
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(123, 198, 176, 1)",
            },
        ],
    };

    const barGraphOptions = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    precision: 0,
                },
                beginAtZero: true,
            },
            x: {
                grid: {
                    display: false,
                },
            },
        },
    };

    const visitedCoursesColumns = [
        {
            Header: <strong>Most Visited Courses</strong>,
            accessor: "title",
        },
        {
            Header: (
                <>
                    User count <Icon icon="ant-design:caret-down-filled" />
                </>
            ),
            accessor: "VisitCount",
        },
    ];

    const completedCoursesColumns = [
        {
            Header: "",
            accessor: "title",
        },
        {
            Header: (
                <>
                    User Count <Icon icon="ant-design:caret-down-filled" />
                </>
            ),
            accessor: "CompletedCount",
        },
    ];

    const ratedCoursesColumns = [
        {
            Header: "",
            accessor: "title",
        },
        {
            Header: (
                <>
                    Rating
                    <Icon icon="ant-design:caret-down-filled" />
                </>
            ),
            accessor: "RateCount",
            Cell: ({ row }) => {
                return <span>{row.original.RateCount.toFixed(1)}</span>;
            },
        },
    ];

    const commentedCoursesColumns = [
        {
            Header: "",
            accessor: "title",
        },
        {
            Header: (
                <>
                    User Count <Icon icon="ant-design:caret-down-filled" />
                </>
            ),
            accessor: "CommentCount",
        },
    ];

    const discussionsColumns = [
        {
            Header: <strong>Most User Engaging Discussions</strong>,
            accessor: "title",
        },
        {
            Header: <Icon icon="ant-design:caret-down-filled" />,
            accessor: "EngagedUsers",
        },
    ];

    return (
        <div className="flex flex-col gap-8 lg:gap-14">
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8">
                <Card className="py-2 bg-gradient-to-r from-[#3BC59C] to-[#3E9F83] shadow-xl flex items-center text-white relative">
                    <div className="flex flex-col">
                        <span className="font-bold">Total App Users</span>
                        <span className="text-4xl font-bold">
                            {numberFormatter(totalUsers)}
                        </span>
                    </div>
                    <UserCardIcon className="absolute -bottom-1 -right-1" />
                </Card>
                <Card className="py-2 bg-gradient-to-l from-[#BF4735] to-[#E1A8A0] shadow-xl flex items-center text-white relative">
                    <div className="flex flex-col">
                        <span className="font-bold">Total Courses</span>
                        <span className="text-4xl font-bold">
                            {numberFormatter(totalCourses)}
                        </span>
                    </div>
                    <BookCardIcon className="absolute -bottom-1 -right-1" />
                </Card>
                <Card className="py-2 bg-gradient-to-l from-[#2C4A6A] to-[#5A7C9F] shadow-xl flex items-center text-white relative">
                    <div className="flex flex-col">
                        <span className="font-bold">Total Discussions</span>
                        <span className="text-4xl font-bold">
                            {numberFormatter(totalDiscussions)}
                        </span>
                    </div>
                    <BubbleCardIcon className="absolute -bottom-1 -right-1" />
                </Card>
                <Card className="py-2 bg-gradient-to-r from-[#E56D53] to-[#B83013] shadow-xl flex items-center text-white relative">
                    <div className="flex flex-col">
                        <span className="font-bold">Active Users</span>
                        <span className="text-4xl font-bold">
                            {numberFormatter(totalActiveUsers)}
                        </span>
                    </div>
                    <BellCardIcon className="absolute -bottom-1 -right-1" />
                </Card>
            </div>
            <Card className="bg-gradient-to-l from-[#2C4A6A] to-[#5A7C9F] text-white">
                <div className="flex justify-between">
                    <span className="uppercase font-bold">Users</span>
                    {/* <Icon icon="mi:filter" className="text-xl cursor-pointer" /> */}
                </div>
                <div className="grid gap-y-8 lg:gap-y-0 lg:grid-cols-2 lg:h-52">
                    <div className="flex items-center justify-center">
                        <div className="w-48">
                            <Doughnut
                                data={usersData}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                }}
                            />
                        </div>
                        <div className="flex flex-col">
                            <span className="text-4xl font-bold">
                                {numberFormatter(totalUsers)}
                            </span>
                            <span className="font-bold">Total App Users</span>
                        </div>
                    </div>
                    <div className="flex lg:flex-col justify-center gap-y-8">
                        <div className="flex items-center">
                            <Icon
                                icon="bx:male"
                                className="text-6xl text-[#7BC6B0]"
                            />
                            <div className="flex flex-col">
                                <span className="text-4xl font-bold">
                                    {totalMales}
                                </span>
                                <span>MALE</span>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <Icon
                                icon="bx:female"
                                className="text-6xl text-[#E56D53]"
                            />
                            <div className="flex flex-col">
                                <span className="text-4xl font-bold">
                                    {totalFemales}
                                </span>
                                <span>FEMALE</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                <Card className="shadow-xl text-gray-500 flex-1 px-0">
                    <div className="flex justify-between">
                        <span className="font-bold">Courses</span>
                    </div>
                    <div className="grid grid-cols-2 lg:grid-cols-3 place-items-center">
                        <div className="flex flex-col">
                            <span className="text-4xl font-bold">
                                {totalCourses}
                            </span>
                            <span>Total Courses</span>
                        </div>
                        <div className="flex items-center justify-center">
                            <div className="w-28">
                                <Doughnut
                                    data={coursesData}
                                    options={{ maintainAspectRatio: false }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col justify-center gap-y-8">
                            <div className="flex text-[#045DB8]">
                                <Icon
                                    icon="carbon:dot-mark"
                                    className="text-2xl"
                                />
                                <div className="flex flex-col">
                                    <span className="font-bold">
                                        {totalPublishedCourses}
                                    </span>
                                    <span>Published</span>
                                </div>
                            </div>
                            <div className="flex text-[#ED942B]">
                                <Icon
                                    icon="carbon:dot-mark"
                                    className="text-2xl"
                                />
                                <div className="flex flex-col">
                                    <span className="font-bold">
                                        {totalUnpublishedCourses}
                                    </span>
                                    <span>Unpublished</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <Table
                        data={visitedCoursesData}
                        columns={visitedCoursesColumns}
                    />
                </Card>
                <div className="flex-1 flex flex-col gap-8">
                    <Card className="shadow-xl bg-gradient-to-r from-[#E56D53] to-[#B83013] text-white flex-1">
                        <div className="uppercase font-bold mb-4">
                            App Usage
                        </div>
                        <div className="h-56">
                            <Line
                                options={lineGraphOptions}
                                data={lineGraphData}
                            />
                        </div>
                    </Card>
                    <Card className="shadow-xl flex-1">
                        <div className="uppercase font-bold mb-4">
                            Age Category
                        </div>
                        <div className="h-56">
                            <Bar
                                options={barGraphOptions}
                                data={barGraphData}
                            />
                        </div>
                    </Card>
                </div>
            </div>
            <div className="grid lg:grid-cols-3 gap-8">
                <Card className="bg-gradient-to-r from-[#3BC59C] to-[#3E9F83] shadow-lg text-white">
                    <div className="flex flex-col">
                        <span className="font-bold text-xl">
                            Most Completed Courses
                        </span>
                        <Table
                            data={completedCoursesData}
                            columns={completedCoursesColumns}
                        />
                    </div>
                </Card>
                <Card className="bg-gradient-to-l from-[#BF4735] to-[#E1A8A0] shadow-lg text-white">
                    <div className="flex flex-col">
                        <span className="font-bold text-xl">
                            Most Rated Courses
                        </span>
                        <Table
                            data={ratedCoursesData}
                            columns={ratedCoursesColumns}
                        />
                    </div>
                </Card>
                <Card className="bg-gradient-to-l from-[#2C4A6A] to-[#5A7C9F] shadow-lg text-white">
                    <div className="flex flex-col">
                        <span className="font-bold text-xl">
                            Most Commented Courses
                        </span>
                        <Table
                            data={commentedCoursesData}
                            columns={commentedCoursesColumns}
                        />
                    </div>
                </Card>
            </div>
            <Card className="rounded shadow-xl text-gray-500 flex-1">
                <div className="flex justify-between">
                    <span className="font-bold">Discussions</span>
                </div>
                <div className="flex flex-col lg:flex-row">
                    <div className="flex-1 grid grid-cols-2 lg:grid-cols-3 place-items-center">
                        <div className="flex flex-col">
                            <span className="text-4xl font-bold">
                                {totalDiscussions}
                            </span>
                            <span>Total Discussions</span>
                        </div>
                        <div className="flex items-center justify-center">
                            <div className="w-28">
                                <Doughnut
                                    data={discussionsCreationData}
                                    options={{ maintainAspectRatio: false }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col justify-center gap-y-8">
                            <div className="flex text-[#045DB8]">
                                <Icon
                                    icon="carbon:dot-mark"
                                    className="text-2xl"
                                />
                                <div className="flex flex-col">
                                    <span className="font-bold">
                                        {userCreatedDiscussions}
                                    </span>
                                    <span>User created</span>
                                </div>
                            </div>
                            <div className="flex text-[#ED942B]">
                                <Icon
                                    icon="carbon:dot-mark"
                                    className="text-2xl"
                                />
                                <div className="flex flex-col">
                                    <span className="font-bold">
                                        {adminCreatedDiscussions}
                                    </span>
                                    <span>Admin created</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Divider type="vertical" className="h-48 hidden lg:block" />
                    <div className="flex-1 grid grid-cols-2 place-items-center mt-8 lg:mt-0">
                        <div className="flex items-center justify-center">
                            <div className="w-28">
                                <Doughnut
                                    data={discussionsStatusData}
                                    options={{ maintainAspectRatio: false }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col justify-center gap-y-4">
                            <div className="flex text-[#EBAFA6]">
                                <Icon
                                    icon="carbon:dot-mark"
                                    className="text-2xl"
                                />
                                <div className="flex flex-col">
                                    <span className="font-bold">
                                        {activeDiscussions}
                                    </span>
                                    <span>Active Discussions</span>
                                </div>
                            </div>
                            <div className="flex text-[#3BC49C]">
                                <Icon
                                    icon="carbon:dot-mark"
                                    className="text-2xl"
                                />
                                <div className="flex flex-col">
                                    <span className="font-bold">
                                        {inactiveDiscussions}
                                    </span>
                                    <span>Inactive Discussions</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <Table
                    data={engagedDiscussionsData}
                    columns={discussionsColumns}
                />
            </Card>
        </div>
    );
}

export default Dashboard;
